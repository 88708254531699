<template>
  <div class="statistics-service-container">
    <div class="search-box">
      <Row>
        <Col span="24">
          <h2 class="search-title border-bottom">按条件搜索</h2>
        </Col>
      </Row>
      <Form class="search-form" :label-width="85" ref="formValidate" :model="searchForm" :rules="ruleValidate">
        <Row>
          <Col span="5">
            <Form-item label="会员姓名：">
              <Input v-model="searchForm.member_name" placeholder="请输入..."></Input>
            </Form-item>
          </Col>
          <Col span="1">&nbsp;</Col>
          <Col span="5">
            <Form-item label="性别：">
              <Select clearable v-model="searchForm.sex" placeholder="请选择">
                <Option :value="v.id" v-for="v in sexList" :key="v.id">{{v.name}}</Option>
              </Select>
            </Form-item>
          </Col>
          <Col span="1">&nbsp;</Col>
          <Col span="8">
            <Form-item label="服务项目：">
              <Select clearable v-model="searchForm.service_type" placeholder="请选择">
                <Option :value="v.id" v-for="v in serviceList" :key="v.id">{{v.name}}</Option>
              </Select>
            </Form-item>
          </Col>
        </Row>
        <Row>
          <Col span="7">
            <Form-item label="服务日期：">
              <Date-picker @on-change="changeTime" type="daterange" placeholder="选择日期"></Date-picker>
            </Form-item>
          </Col>
          <Col span="1">&nbsp;</Col>
          <Col span="7">
            <Form-item label="服务人：">
              <Cascader :data="staff" v-model="staffArr"></Cascader>
            </Form-item>
          </Col>
          <Col span="1">&nbsp;</Col>
          <Col span="7">
            <Form-item label="年龄：">
              <Row>
                <Col span="10">
                  <Form-item prop="age_start">
                    <Input v-model="searchForm.age_start" placeholder="请输入..."></Input>
                  </Form-item>
                </Col>
                <Col span="2" class="tac">至</Col>
                <Col span="10">
                  <Form-item prop="age_end">
                    <Input v-model="searchForm.age_end" placeholder="请输入..."></Input>
                  </Form-item>
                </Col>
                <Col span="2" class="tac">岁</Col>
              </Row>
            </Form-item>
          </Col>
        </Row>
        <Row>
          <Col span="7">
            <Form-item label="省份：">
              <Input disabled v-if="this.power.indexOf('数据统计--查询全国数据的权限') === -1 && this.power.indexOf('数据统计--查询区域数据的权限') === -1" v-model="pro_name"></Input>
              <Select clearable v-model="searchForm.belong_pid" placeholder="请选择" @on-change="changePro" v-else>
                <Option :value="v.id" v-for="v in proList" :key="v.id">{{v.name}}</Option>
              </Select>
            </Form-item>
          </Col>
          <Col span="1">&nbsp;</Col>
          <Col span="7">
            <Form-item label="城市：">
              <Input disabled v-if="this.power.indexOf('数据统计--查询全国数据的权限') === -1 && this.power.indexOf('数据统计--查询区域数据的权限') === -1" v-model="city_name"></Input>
              <Select clearable v-model="searchForm.belong_cid" placeholder="请选择" @on-change="changeCity" v-else>
                <Option :value="v.id" v-for="v in cityList" :key="v.id">{{v.name}}</Option>
              </Select>
            </Form-item>
          </Col>
          <Col span="1">&nbsp;</Col>
          <Col span="7">
            <Form-item label="门店：">
              <Input disabled v-if="this.power.indexOf('数据统计--查询全国数据的权限') === -1 && this.power.indexOf('数据统计--查询区域数据的权限') === -1" v-model="branch_name"></Input>
              <Select clearable v-model="searchForm.belong_sid" placeholder="请选择" v-else>
                <Option :value="v.id" v-for="v in storeList" :key="v.id">{{v.name}}</Option>
              </Select>
            </Form-item>
          </Col>
        </Row>
        <Row>
          <Col span="5">
              <Form-item label="服务纪录：">
                <Select clearable v-model="searchForm.service_flag" placeholder="请选择" @on-change="changeServiceFlag">
                  <Option :value="v.value" v-for="v in serviceFLagList" :key="v.value">{{ v.label }}</Option>
                </Select>
              </Form-item>
            </Col>
        </Row>
        <div class="tac">
          <Button type="primary" class="search-btn" @click="handleSubmit('formValidate')">搜索</Button>
        </div>
      </Form>
    </div>
    <div class="export-div clearfix">
      <a href="javascript:;" class="export-btn" @click="exportData()" v-if="this.power.indexOf('数据统计--数据导出权限') > -1">导出数据</a>
    </div>
    <Table stripe :columns="columns" :data="serviceData" @on-selection-change="selectOne" ref="table"></Table>
    <Row>
      <Col span="12">
        <div class="table-oprate">
          <!--<Button @click="">删除</Button>-->
        </div>
      </Col>
      <Col span="12">
        <span class="records">共{{pageTotal}}条记录</span>
      </Col>
    </Row>
    <div class="page-nations-wrapper">
      <div class="page-nations">
        <Page :total="pageTotal" :current="searchForm.page" :page-size="searchForm.size" @on-change="changePage"></Page>
      </div>
    </div>
  </div>
</template>

<script type="text/ecmascript-6">
import statisticsService from '@/services/statisticsService';
export default {
	data() {
		const ageSmall = (rule, value, callback) => {
			if (value === '') {
				if (this.searchForm.age_end !== '') {
					this.$refs.formValidate.validateField('age_end');
				}
				callback();
			} else {
				if (Number.isNaN(Number(value))) {
					callback(new Error('请输入数字值'));
				} else {
					if (this.searchForm.age_end !== '') {
						this.$refs.formValidate.validateField('age_end');
					}
					callback();
				}
			}
		};
		const ageLarge = (rule, value, callback) => {
			if (value === '') {
				callback();
			} else {
				if (Number.isNaN(Number(value))) {
					callback(new Error('请输入数字值'));
				} else {
					if (
						this.searchForm.age_start !== '' &&
						Number(value) < Number(this.searchForm.age_start)
					) {
						callback(new Error('请输入更大数值'));
					}
					callback();
				}
			}
		};
		return {
			searchForm: {
				page: 1,
				size: 10,
				member_name: '',
				sex: '',
				belong_pid: '',
				belong_cid: '',
				belong_sid: '',
				start_time: '',
				end_time: '',
				age_start: '',
				age_end: '',
				service_type: '',
				staff: '',
				service_flag: 1,
			},
			exportForm: {
				member_name: '',
				sex: '',
				belong_pid: '',
				belong_cid: '',
				belong_sid: '',
				start_time: '',
				end_time: '',
				age_start: '',
				age_end: '',
				service_type: '',
				staff: '',
			},
			sexList: [
				{
					id: 0,
					name: '男',
				},
				{
					id: 1,
					name: '女',
				},
			],
			serviceFLagList: [
				{
					value: '0',
					label: '无',
				},
				{
					value: '1',
					label: '有',
				},
			],
			proList: [],
			cityList: [],
			city: {},
			storeList: [],
			store: {},
			serviceList: [],
			staff: [],
			staffArr: [],
			columns: [
				// { type: 'selection', width: 60, align: 'center' },
				{ title: '所属分院', key: 'branch_name', align: 'center' },
				{
					title: '会员姓名',
					key: 'member_name',
					align: 'center',
					render: (h, params) => {
						return h('div', [
							h(
								'a',
								{
									style: {
										color: '#ff8400',
									},
									attrs: {
										target: '_blank',
										href:
											'#/base/member/detail/' +
											this.serviceData[params.index].member_id +
											'/' +
											2 +
											'/' +
											1,
									},
								},
								params.row.member_name,
							),
						]);
					},
				},
				{
					title: '性别',
					key: 'sex',
					align: 'center',
					render: (h, params) => {
						if (params.row.sex === 0) {
							return h('div', '男');
						} else if (params.row.sex === 1) {
							return h('div', '女');
						} else {
							return h('div', params.row.sex);
						}
					},
				},
				{
					title: '年龄',
					key: 'age',
					align: 'center',
					render: (h, params) => {
						if (params.row.age === null) {
							return h('div', params.row.age);
						} else {
							return h('div', params.row.age + '岁');
						}
					},
				},
				{
					title: '服务项目',
					key: 'service_name',
					align: 'center',
				},
				{
					title: '服务人',
					key: 'staff_name',
					align: 'center',
				},
				{ title: '服务日期', key: 'create_time', align: 'center' },
			],
			serviceData: [],
			pageTotal: 0,
			ruleValidate: {
				age_start: [{ validator: ageSmall, trigger: 'blur' }],
				age_end: [{ validator: ageLarge, trigger: 'blur' }],
			},
			power: '',
			pro_name: '',
			city_name: '',
			branch_name: '',
		};
	},
	watch: {
		staffArr() {
			this.searchForm.staff = this.staffArr[1];
		},
	},
	created() {
		this.power = localStorage.getItem('userPower');
		this.pro_name = JSON.parse(localStorage.getItem('userInfo')).user_info.pro_name;
		this.city_name = JSON.parse(localStorage.getItem('userInfo')).user_info.city_name;
		this.branch_name = JSON.parse(localStorage.getItem('userInfo')).user_info.branch_name;
		if (
			this.power.indexOf('数据统计--查询全国数据的权限') === -1 &&
			this.power.indexOf('数据统计--查询区域数据的权限') === -1
		) {
			this.searchForm.belong_pid = JSON.parse(localStorage.getItem('userInfo')).user_info.pid;
			this.searchForm.belong_cid = JSON.parse(localStorage.getItem('userInfo')).user_info.cid;
			this.searchForm.belong_sid = JSON.parse(localStorage.getItem('userInfo')).user_info.sid;
		}
		this.getServicesData();
		statisticsService.getServicesSelect().then((data) => {
			this.proList = data.branch.pro;
			this.city = data.branch.city;
			this.store = data.branch.store;
			this.serviceList = data.service_type;
			this.staff = data.staff_list;
		});
	},
	methods: {
		changePro() {
			this.cityList = this.city[this.searchForm.belong_pid];
			this.searchForm.belong_cid = '';
		},
		changeCity() {
			this.storeList = this.store[this.searchForm.belong_cid];
			this.searchForm.belong_sid = '';
		},
		getServicesData() {
			statisticsService.getServicesData(this.searchForm).then((data) => {
				this.serviceData = data.list;
				this.pageTotal = data.row_size;
			});
		},
		handleSubmit(name) {
			this.$refs[name].validate((valid) => {
				if (valid) {
					this.searchForm.page = 1;
					this.getServicesData();
					this.exportForm.member_name = this.searchForm.member_name;
					this.exportForm.sex = this.searchForm.sex;
					this.exportForm.belong_pid = this.searchForm.belong_pid;
					this.exportForm.belong_cid = this.searchForm.belong_cid;
					this.exportForm.belong_sid = this.searchForm.belong_sid;
					this.exportForm.start_time = this.searchForm.start_time;
					this.exportForm.end_time = this.searchForm.end_time || '';
					this.exportForm.service_type = this.searchForm.service_type;
					this.exportForm.staff = this.searchForm.staff || '';
					this.exportForm.age_start = this.searchForm.age_start;
					this.exportForm.age_end = this.searchForm.age_end;
				}
			});
		},
		changeTime(date) {
			this.searchForm.start_time = date[0];
			this.searchForm.end_time = date[1];
		},
		changePage(page) {
			this.searchForm.page = page;
			this.getServicesData();
		},
		selectOne() {},
		exportData() {
			location.href =
				'http://conlife.sskh.net:9928/api/service/export?member_name=' +
				this.exportForm.member_name +
				'&sex=' +
				this.exportForm.sex +
				'&belong_pid=' +
				this.exportForm.belong_pid +
				'&belong_cid=' +
				this.exportForm.belong_cid +
				'&belong_sid=' +
				this.exportForm.belong_sid +
				'&start_time=' +
				this.exportForm.start_time +
				'&end_time=' +
				this.exportForm.end_time +
				'&age_start=' +
				this.exportForm.age_start +
				'&age_end=' +
				this.exportForm.age_end +
				'&service_type=' +
				this.exportForm.service_type +
				'&staff=' +
				this.exportForm.staff;
		},
	},
};
</script>

<style lang="css" scoped>
.statistics-service-container .search-box {
  border: 1px solid #d7dde4;
}
</style>
